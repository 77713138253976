@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;500;800&family=Poppins:wght@200;300;400;600;700&display=swap');

* {
  margin: 0 0;
  padding: 0 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

// ----- Scrollbar -----

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: rgba(14, 185, 168, 0.05);
  box-shadow: inset 0 0 2px grey;
}
::-webkit-scrollbar-thumb {
  background: #256fac;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
  background: #00665c;
}

// ----- Corpo -----

body {
  background-color: rgba(14, 185, 168, 0.05);
}

h4,
h5,
h6 {
  margin: 0;
}

#intro-anim {
  width: 100%;
  height: 100vh;
  background-color: #256fac;
}
