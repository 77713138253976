* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#gallery-container {
  position: relative;
  margin: 0;
  padding: 0;

  .image-gallery {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;
    grid-gap: 20px;

    .image-box {
      position: relative;
      background-color: #d7d7d8;
      overflow: hidden;

      &:nth-child(5n + 1) {
        grid-column: span 2;
        grid-row: span 2;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.6s ease;

        &:hover {
          transform: scale(1.02);
        }
      }
    }
  }
}

@media (max-width: 1120px) {
  #gallery-container {
    #image-gallery {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-auto-rows: 250px;

      .image-box {
        &:nth-child(5n + 1) {
          grid-column: unset;
          grid-row: unset;
        }
      }
    }
  }
}

@media (max-width: 830px) {
  #gallery-container {
    .image-gallery {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-auto-rows: 150px;

      .image-box {
        &:nth-child(5n + 1) {
          grid-column: span 2;
          grid-row: span 2;
        }
      }
      .desappear {
        display: none;
      }
    }
  }
}
