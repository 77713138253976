#brands-container {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #cfdfe4;
  height: 500px;

  h1 {
    text-align: center;
    color: white;
    padding: 10px;
  }
  #image-brands {
    width: 100%;
    max-width: 1000px;
    height: 420px;
    margin: 0 auto;
    padding: 50px 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    grid-auto-rows: 70px;
    grid-auto-flow: dense;
    grid-gap: 50px;
    .image-box {
      position: relative;
      overflow: hidden;

      h2 {
        font-size: x-large;
      }

      img {
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
    }
  }
}
@media (max-width: 930px) {
  #brands-container {
    height: 500px;
    #image-brands {
      width: 80%;
      padding: 50px 20px;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-auto-rows: 55px;
      grid-gap: 40px;
    }
  }
}
@media (max-width: 700px) {
  #brands-container {
    height: 600px;
    #image-brands {
      width: 80%;
      padding: 50px 20px;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-auto-rows: 55px;
      grid-gap: 40px;
    }
  }
}
@media (max-width: 550px) {
  #brands-container {
    height: 650px;
    #image-brands {
      width: 80%;
      padding: 50px 20px;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-auto-rows: 55px;
      grid-gap: 40px;
    }
  }
}
@media (max-width: 524px) {
  #brands-container {
    height: 780px;
    
    h1 {
      font-size: 25px;
    }
  }
}
