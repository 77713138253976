#intro-anim {
  animation-name: introbg;
  animation-duration: 4s;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100vh;
  background-color: #256fac;
  display: flex;
  justify-self: center;
  align-items: center;
  pointer-events: none;
  .locker-div {
    margin: 0 auto;
    .locker-img {
      animation: introAnim;
      animation-duration: 4s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
      height: auto;
    }
  }
}

@keyframes introAnim {
  0.0% {
    transform: rotate(0deg);
    opacity: 0;
  }
  39.7% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  56.3% {
    transform: rotate(-360deg) scale(1);
  }
  58.3% {
    transform: rotate(-360deg) scale(0.95);
  }
  60.1% {
    transform: rotate(-360deg) scale(1.05);
  }
  62.2% {
    transform: rotate(-360deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: rotate(-360deg);
    opacity: 0;
  }
}
@keyframes introbg {
  0.0% {
    opacity: 100;
  }
  28.2% {
    opacity: 100;
  }
  33.8% {
    opacity: 100;
  }
  38.2% {
    opacity: 100;
  }
  42.4% {
    opacity: 100;
  }
  66.3% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}
