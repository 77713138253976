#card-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin: 40px auto;
  .card-class {
    display: flex;
    justify-content: center;
    width: 286px;
    box-shadow: 0px 0px 2px black;
    transition: all 0.5s ease;
    min-height: 410px;
    max-height: 460px;

    img {
      max-width: 350px;
    }

    &:hover {
      box-shadow: 0px 0px 8px black;
      transition: all 0.2s ease;
    }
  }
  .card-title {
    font-size: medium;
  }
  .card-text {
    font-size: small;
  }
  .text-muted {
    font-size: 12px;
  }
}
@media (max-width: 1120px) {
  #card-container {
    .card-class {
      width: 250px;
      min-height: 380px;

      &:hover {
        .card-img {
          transition: 0s;
          padding: 0px;
        }
      }
    }
  }
}
@media (max-width: 880px) {
  #card-container {
    flex-direction: column;
    .card-class {
      width: 350px;
      min-height: 380px;
      margin-bottom: 80px;

      &:hover {
        .card-img {
          transition: 0s;
          padding: 0px;
        }
      }
    }
  }
}
