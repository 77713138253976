#main-header {
  width: 100%;
  height: 70px;
  background-color: #256fac;

  .header-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    height: 100%;
  }

  #menu-logo {
    .big-logo {
      max-width: 330px;
      display: block;

      &:hover {
        filter: drop-shadow(0px 0px 1.5px white);
        transition: 0.3s;
      }
    }

    .small-logo {
      display: none;
      max-width: 80px;
    }
  }

  #menu-header {
    ul {
      display: flex;
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        margin: 0px auto;

        .menu-link {
          text-decoration: none;
          color: white;
          font-weight: 600;
          padding: 0 20px;
          border-radius: 2px;

          &:hover {
            filter: drop-shadow(0px 0px 1px white);
            transition: 0.4s;
          }
        }
      }
    }
  }

  #contato {
    width: 330px;

    .socials {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      a {
        text-decoration: none;

        img {
          max-width: 20px;
          max-height: 20px;
          margin-top: 5px;
          margin-right: 5px;

          &:hover {
            filter: drop-shadow(0px 0px 1.5px white);
            transition: 0.3s;
          }
        }
      }
    }
    .local {
      display: flex;
      justify-content: center;
      margin-top: 2px;
      img {
        max-width: 20px;
        max-height: 20px;
        margin-top: 5px;
        margin-right: 5px;
      }
      span {
        color: white;
        font-size: 15px;
        margin-top: 4px;
      }
      .mapsButton {
        color: white;
        text-decoration: none;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 922px) {
  #main-header {
    #menu-logo {
      .big-logo {
        max-width: 330px;
        display: none;
      }
      .small-logo {
        display: block;
      }
    }
    #menu-header {
      ul {
        padding-right: 10px;
        li {
          .menu-link {
            padding: 0 10px;
          }
        }
      }
    }

    #contato {
      display: none;
      width: 80px;
      a {
        padding-left: 20px;
      }
      .local {
        display: none;
      }
    }
  }
}
