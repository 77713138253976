#main-content {
  padding: 0 0;
  margin: 0 0;
  min-height: 90vh;
  #support-container {
    display: flex;
    justify-content: center;
    width: 100%;
    #form-container {
      #form-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        margin: 0 auto;
        padding-bottom: 5vh;

        .section-title {
          text-align: center;
          font-weight: bold;
          margin: 30px 0;
          border-left: 8px solid #016ac5;
          border-right: 8px solid #016ac5;
          padding-left: 14px;
          padding-right: 14px;
        }
      }

      #form-body {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        width: 100%;

        .contact-info {
          width: 100%;
          padding-right: 60px;
          padding-left: 60px;
          .contact-info-place {
            padding-bottom: 40px;
            .contact-info-title {
              color: #016ac5;
              font-weight: 600;
            }
            p {
              margin-bottom: 0px;
            }
          }
          .contact-info-opening-hours {
            .contact-info-opening-hours-title {
              font-weight: 600;
              color: #016ac5;
            }
          }
        }

        .vertical-line-contact-page {
          height: 420px;
          border-right: 5px solid #256fac;
        }

        .contact-form {
          padding-left: 50px;
          width: 90%;
          display: flex;
          justify-content: first baseline;
          flex-direction: column;

          input {
            padding: 10px 10px;
            margin-bottom: 20px;
            border-radius: 08px;
            border: 1px solid #333;
          }

          select {
            padding: 10px 4.5px;
            margin-bottom: 20px;
            border-radius: 08px;
            border: 1px solid #333;
          }
          .comment {
            padding-bottom: 80px;
          }
        }
      }
    }
  }
}

@media (max-width: 830px) {
  #form-container {
    #form-header {
      width: 100%;
    }
    #form-body {
      flex-direction: column;
      width: 80%;
      align-items: center;
      margin: 0 auto;

      .contact-info {
        width: 100%;
        padding: 0;
      }

      .vertical-line-contact-page {
        display: none;
      }

      .contact-form {
        padding-top: 70px;
        padding: 50px 0;
        width: 320px;
        flex-direction: column;
      }
      input {
        border-radius: 08px;
        border: 1px solid #333;
      }
      select {
        border-radius: 08px;
        border: 1px solid #333;
      }
    }
  }
}
